<template>
  <div>
    <h4 class="blue-text font-weight-bold">
      <font-awesome-icon
        @click="$router.go(-1)"
        class="mr-3"
        :icon="{ prefix: 'fas', iconName: 'arrow-left' }"
      />
      <span>配信画像リスト</span>
    </h4>
    <div v-if="listingDetailsData">
      <div id="listing-details" v-if="summary === false">
        <div class="row">
          <div class="col-12 col-md-12 col-lg-12 col-xl-9">
            <div class="row">
              <div class="col-12">
                <p class="blue-text font-weight-bold">
                  <span>画像</span>
                  <span class="float-right">
                    {{ pagination.data.current_page }}/{{
                      pagination.data.last_page
                    }}
                    枚
                  </span>
                </p>
              </div>
              <div v-if="listingDetailsData.status == 4" class="col-12 col-md-12 col-lg-12 col-xl-6 mb-4">
                <p class="mb-1 font-weight-bold">元のアップロードされた画像</p>
                <div class="image">
                  <div class="image">
                    <b-carousel
                      class="img-carousel"
                      :interval="0"
                      :controls="
                        listingDetailsData.current_revisions.prev.length > 1
                          ? true
                          : false
                      "
                      :indicators="
                        listingDetailsData.current_revisions.prev.length > 1
                          ? true
                          : false
                      "
                      @sliding-start="onSlideStart"
                    >
                      <b-carousel-slide
                        v-for="(el, i) in listingDetailsData.current_revisions
                          .prev"
                        :key="i"
                      >
                        <template #img>
                          <img
                            :id="'carousel-img-' + i"
                            :data-filename="el.result_filename"
                            :src="el.result_url"
                            onerror="this.src='/img/image-error.png'"
                            @click="listingDetailsData.staging_request.request_category == 2 ? openImage(el.result_url) : openModal(el.result_url)"
                          />
                        </template>
                      </b-carousel-slide>
                    </b-carousel>
                  </div>
                </div>
                <div class="p-4 lightest-grey">
                  <p>
                    <span>画像名:</span>
                    <span> {{ carousel.filename }}</span>
                  </p>
                  <p>
                    <span>お部屋の種類:</span>
                    <span>
                      {{
                        listingDetailsData.room_area
                          ? listingDetailsData.room_area.name
                          : ''
                      }}
                    </span>
                  </p>
                  <p v-if="listingDetailsData.furnitures.length > 0">
                    <span>家具:</span>
                    <span
                      v-for="(el, i) in listingDetailsData.furnitures"
                      :key="i"
                    >
                      <span v-if="el.furniture">
                        {{
                          listingDetailsData.furnitures.length !== i + 1
                            ? el.furniture.name + ','
                            : el.furniture.name
                        }}
                      </span>
                    </span>
                  </p>
                  <p v-if="listingDetailsData.additional_options.length > 0">
                    <span>追加オプション:</span>
                    <span
                      v-for="(el, i) in listingDetailsData.additional_options"
                      :key="i"
                    >
                      {{
                        listingDetailsData.additional_options.length !== i + 1
                          ? el.additional_option.name + ','
                          : el.additional_option.name
                      }}
                    </span>
                  </p>
                  <p v-if="listingDetailsData.remarks">
                    <span>ユーザーからのコメント:</span>
                    <span>
                      {{
                        listingDetailsData.remarks
                          ? listingDetailsData.remarks
                          : ''
                      }}
                    </span>
                  </p>

                  <p>
                    <span>注文カテゴリ:</span>
                    <span v-if="listingDetailsData.staging_request.request_category == 1">
                      デジタルステージング
                    </span>
                    <span v-if="listingDetailsData.staging_request.request_category == 2">
                      360°デジタルステージング
                    </span>
                    <span v-if="listingDetailsData.staging_request.request_category == 3">
                      家具消し
                    </span>
                    <span v-if="listingDetailsData.staging_request.request_category == 4">
                      商業用デジタルステージング
                    </span>
                    <span v-if="listingDetailsData.staging_request.request_category == 5">
                      デジタルリノベーション
                    </span>
                  </p>
                  <p>
                    <span>部屋のスタイル:</span>
                    <span>
                      {{
                        listingDetailsData.staging_request.style
                          ? listingDetailsData.staging_request.style.name
                          : ''
                      }}
                    </span>
                  </p>
                  <p v-if="listingDetailsData.revisions.length != 0">
                    <span>管理からの修正コメント:</span>
                    <span>
                      {{ listingDetailsData.revisions[0].message }}
                    </span>
                  </p>
                </div>
              </div>
              <div v-if="listingDetailsData.status == 6" class="col-12 col-md-12 col-lg-12 col-xl-6 mb-4">
                <p class="mb-1 font-weight-bold">以前のバージョン</p>
                <div class="image">
                  <div class="image">
                    <b-carousel
                      class="img-carousel"
                      :interval="0"
                      :controls="
                        old_versions.length > 1
                          ? true
                          : false
                      "
                      :indicators="
                        old_versions.length > 1
                          ? true
                          : false
                      "
                      @sliding-start="onSlideStart"
                    >
                      <b-carousel-slide
                        v-for="(ov, i) in old_versions"
                        :key="i"
                      >
                        <template #img>
                          <img
                            :id="'carousel-img-' + i"
                            :data-filename="setFileName(ov)"
                            :src="ov.result_file_url"
                            onerror="this.src='/img/image-error.png'"
                            @click="listingDetailsData.staging_request.request_category == 2 ? openImage(ov.result_file_url) : openModal(ov.result_file_url)"
                          />
                        </template>
                      </b-carousel-slide>
                    </b-carousel>
                  </div>
                </div>
                <div class="p-4 lightest-grey">
                  <p>
                    <span>画像名:</span>
                    <span> {{ carousel.filename }}</span>
                  </p>
                  <p>
                    <span>お部屋の種類:</span>
                    <span>
                      {{
                        listingDetailsData.room_area
                          ? listingDetailsData.room_area.name
                          : ''
                      }}
                    </span>
                  </p>
                  <p v-if="listingDetailsData.furnitures.length > 0">
                    <span>家具:</span>
                    <span
                      v-for="(el, i) in listingDetailsData.furnitures"
                      :key="i"
                    >
                      <span v-if="el.furniture">
                        {{
                          listingDetailsData.furnitures.length !== i + 1
                            ? el.furniture.name + ','
                            : el.furniture.name
                        }}
                      </span>
                    </span>
                  </p>
                  <p v-if="listingDetailsData.additional_options.length > 0">
                    <span>追加オプション:</span>
                    <span
                      v-for="(el, i) in listingDetailsData.additional_options"
                      :key="i"
                    >
                      {{
                        listingDetailsData.additional_options.length !== i + 1
                          ? el.additional_option.name + ','
                          : el.additional_option.name
                      }}
                    </span>
                  </p>
                  <p v-if="listingDetailsData.remarks">
                    <span>ユーザーからのコメント:</span>
                    <span>
                      {{
                        listingDetailsData.remarks
                          ? listingDetailsData.remarks
                          : ''
                      }}
                    </span>
                  </p>

                  <p>
                    <span>注文カテゴリ:</span>
                    <span v-if="listingDetailsData.staging_request.request_category == 1">
                      デジタルステージング
                    </span>
                    <span v-if="listingDetailsData.staging_request.request_category == 2">
                      360°デジタルステージング
                    </span>
                    <span v-if="listingDetailsData.staging_request.request_category == 3">
                      家具消し
                    </span>
                    <span v-if="listingDetailsData.staging_request.request_category == 5">
                      デジタルリノベーション
                    </span>
                  </p>
                  <p>
                    <span>部屋のスタイル:</span>
                    <span>
                      {{
                        listingDetailsData.staging_request.style
                          ? listingDetailsData.staging_request.style.name
                          : ''
                      }}
                    </span>
                  </p>
                  <p v-if="listingDetailsData.corrections.length != 0">
                    <span>ユーザ修正の依頼コメント:</span>
                    <span>
                      {{ listingDetailsData.corrections[0].message }}
                    </span>
                  </p>
                </div>
              </div>
              <div v-if="listingDetailsData.status == 4" class="col-12 col-md-12 col-lg-12 col-xl-6 mb-4">
                <p class="mb-1 font-weight-bold">編集した画像</p>
                <div class="image">
                  <img
                      :src="listingDetailsData.current_revisions.revised.result_url"
                      onerror="this.src='/img/image-error.png'"
                      @click="listingDetailsData.staging_request.request_category == 2 ? openImage(listingDetailsData.current_revisions.revised.result_url) : openModal(listingDetailsData.current_revisions.revised.result_url)"
                    />
                </div>
                <div class="p-4 lightest-grey">
                  <div
                    class="d-flex justify-content-center mb-3 approval-actions"
                  >
                    <button
                      :class="
                        approvalsData.find(
                          (el) => el.request_item_id === listingDetailsData.id
                        ).approved === false
                          ? 'btn btn-ds border-radius-5 red white-text mr-2'
                          : 'btn btn-ds border-radius-5 red white-text mr-2 opacity-3'
                      "
                      type="submit"
                      v-waves.light
                      v-on:click="
                        setSelectedApproval(listingDetailsData.id, false)
                      "
                    >
                      <font-awesome-icon
                        :icon="{ prefix: 'fas', iconName: 'times' }"
                      />
                    </button>
                    <button
                      :class="
                        approvalsData.find(
                          (el) => el.request_item_id === listingDetailsData.id
                        ).approved === true
                          ? 'btn btn-ds border-radius-5 green white-text ml-2'
                          : 'btn btn-ds border-radius-5 green white-text ml-2 opacity-3'
                      "
                      type="submit"
                      v-waves.light
                      v-on:click="
                        setSelectedApproval(listingDetailsData.id, true)
                      "
                    >
                      <font-awesome-icon
                        :icon="{ prefix: 'fas', iconName: 'check' }"
                      />
                    </button>
                  </div>
                  <b-form-textarea
                    v-if="
                      approvalsData.find(
                        (el) => el.request_item_id === listingDetailsData.id
                      ).approved === false
                    "
                    v-model="
                      approvalsData.find(
                        (el) => el.request_item_id === listingDetailsData.id
                      ).comment
                    "
                    :placeholder="
                      approvalsData.find(
                        (el) => el.request_item_id === listingDetailsData.id
                      ).approved === true
                        ? '以下の特殊な記号は使えません。 !@#$%^&*()[]'
                        : approvalsData.find(
                            (el) => el.request_item_id === listingDetailsData.id
                          ).approved === false
                        ? '不承認の場合はコメントが必要です。'
                        : '以下の特殊な記号は使えません。 !@#$%^&*()[]'
                    "
                    rows="5"
                    max-rows="5"
                  ></b-form-textarea>
                </div>
              </div>
              <div v-if="listingDetailsData.status == 6" class="col-12 col-md-12 col-lg-12 col-xl-6 mb-4">
                <p class="mb-1 font-weight-bold">編集した画像</p>
                <div class="image">
                  <img
                      :src="current_version.result_file_url"
                      onerror="this.src='/img/image-error.png'"
                      @click="listingDetailsData.staging_request.request_category == 2 ? openImage(current_version.result_file_url) : openModal(current_version.result_file_url)"
                    />
                </div>
                <div class="p-4 lightest-grey">
                  <div
                    class="d-flex justify-content-center mb-3 approval-actions"
                  >
                    <button
                      :class="
                        approvalsData.find(
                          (el) => el.request_item_id === listingDetailsData.id
                        ).approved === false
                          ? 'btn btn-ds border-radius-5 red white-text mr-2'
                          : 'btn btn-ds border-radius-5 red white-text mr-2 opacity-3'
                      "
                      type="submit"
                      v-waves.light
                      v-on:click="
                        setSelectedApproval(listingDetailsData.id, false)
                      "
                    >
                      <font-awesome-icon
                        :icon="{ prefix: 'fas', iconName: 'times' }"
                      />
                    </button>
                    <button
                      :class="
                        approvalsData.find(
                          (el) => el.request_item_id === listingDetailsData.id
                        ).approved === true
                          ? 'btn btn-ds border-radius-5 green white-text ml-2'
                          : 'btn btn-ds border-radius-5 green white-text ml-2 opacity-3'
                      "
                      type="submit"
                      v-waves.light
                      v-on:click="
                        setSelectedApproval(listingDetailsData.id, true)
                      "
                    >
                      <font-awesome-icon
                        :icon="{ prefix: 'fas', iconName: 'check' }"
                      />
                    </button>
                  </div>
                  <b-form-textarea
                    v-if="
                      approvalsData.find(
                        (el) => el.request_item_id === listingDetailsData.id
                      ).approved === false
                    "
                    v-model="
                      approvalsData.find(
                        (el) => el.request_item_id === listingDetailsData.id
                      ).comment
                    "
                    :placeholder="
                      approvalsData.find(
                        (el) => el.request_item_id === listingDetailsData.id
                      ).approved === true
                        ? '以下の特殊な記号は使えません。 !@#$%^&*()[]'
                        : approvalsData.find(
                            (el) => el.request_item_id === listingDetailsData.id
                          ).approved === false
                        ? '不承認の場合はコメントが必要です。'
                        : '以下の特殊な記号は使えません。 !@#$%^&*()[]'
                    "
                    rows="5"
                    max-rows="5"
                  ></b-form-textarea>
                </div>
              </div>
              <div class="col-12">
                <div class="actions">
                  <button
                    class="btn btn-ds mt-3 white dark-blue-outline dark-blue-text"
                    v-waves.light
                    v-on:click="goToPrevious(pagination.data.from)"
                    v-if="pagination.data.from !== pagination.currentPage"
                  >
                    <font-awesome-icon
                      :icon="{ prefix: 'fas', iconName: 'chevron-left' }"
                    />
                    <span class="ml-3">もどる</span>
                  </button>
                  <button
                    class="btn btn-ds mt-3 dark-blue white-text"
                    v-waves.light
                    v-on:click="goToNext(pagination.data.from)"
                    v-if="pagination.data.from !== pagination.data.last_page"
                    :disabled="
                      approvalsData.find(
                        (el) => el.request_item_id === listingDetailsData.id
                      ).approved === true
                        ? false
                        : approvalsData.find(
                            (el) => el.request_item_id === listingDetailsData.id
                          ).approved === false &&
                          approvalsData.find(
                            (el) => el.request_item_id === listingDetailsData.id
                          ).comment
                        ? false
                        : true
                    "
                  >
                    <span class="mr-3">つぎへ</span>
                    <font-awesome-icon
                      :icon="{ prefix: 'fas', iconName: 'chevron-right' }"
                    />
                  </button>
                  <button
                    class="btn btn-ds mt-3 dark-blue white-text"
                    v-waves.light
                    v-on:click="setSummary"
                    :disabled="
                      approvalsData.find(
                        (el) => el.request_item_id === listingDetailsData.id
                      ).approved === true
                        ? false
                        : approvalsData.find(
                            (el) => el.request_item_id === listingDetailsData.id
                          ).approved === false &&
                          approvalsData.find(
                            (el) => el.request_item_id === listingDetailsData.id
                          ).comment
                        ? false
                        : true
                    "
                    v-else
                  >
                    <span class="mr-3">OK</span>
                    <font-awesome-icon
                      :icon="{ prefix: 'fas', iconName: 'chevron-right' }"
                    />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="listing-summary-details" v-else>
        <h4 class="mt-3 blue-text font-weight-bold">確認</h4>
        <div v-for="(el, i) in approvalsData" :key="i">
          <div class="row summary-item">
            <div class="col-12 col-md-5 col-lg-4 col-xl-4 image">
              <img v-if="listingDetailsData.status != 6"
                :src="el.image"
                onerror="this.src='/img/image-error.png'"
                @click="listingDetailsData.staging_request.request_category == 2 ? openImage(el.image) : openModal(el.image)"
              />
              <img v-if="listingDetailsData.status == 6"
                :src="el.confirm_img"
                onerror="this.src='/img/image-error.png'"
                @click="listingDetailsData.staging_request.request_category == 2 ? openImage(el.confirm_img) : openModal(el.confirm_img)"
              />
            </div>
            <div class="col-12 col-md-7 col-lg-8 col-xl-8 details">
              <p class="font-weight-bold">オーダー番号：</p>
              <p class="ml-2 mt-1">{{ listingDetailsData.staging_request.id }}</p>
              <p class="font-weight-bold">評価：</p>
              <div class="d-flex text-justify" v-if="el.approved">
                <div
                  class="font-weight-bold border-radius-5 green white-text ml-2"
                  style="height: 28px; padding: 5px 8px; font-size: 14px"
                >
                  <font-awesome-icon
                    :icon="{
                      prefix: 'fas',
                      iconName: 'check',
                    }"
                  />
                </div>
                <p class="ml-2 mt-1">承認済み</p>
              </div>
              <div class="d-flex text-justify" v-else>
                <div
                  class="font-weight-bold border-radius-5 red white-text ml-2"
                  style="height: 28px; padding: 5px 10px; font-size: 14px"
                >
                  <font-awesome-icon
                    :icon="{
                      prefix: 'fas',
                      iconName: 'times',
                    }"
                  />
                </div>
                <p class="ml-2 mt-1">不承認</p>
              </div>
              <div class="mt-3" v-if="el.comment">
                <p class="font-weight-bold mb-1">コメント:</p>
                <pre><p>{{ el.comment }}</p></pre>
              </div>
            </div>
          </div>
        </div>
        <b-alert
          class="m-0 mt-3 light-grey-outline grey-text"
          variant="warning"
          show
          v-if="formMessage !== null"
        >
          {{ formMessage }}
        </b-alert>
        <div class="actions">
          <button
            class="btn btn-ds mt-3 white dark-blue-outline dark-blue-text"
            v-waves.light
            v-on:click="goToPrevious(pagination.data.from + 1)"
          >
            <font-awesome-icon
              :icon="{ prefix: 'fas', iconName: 'chevron-left' }"
            />
            <span class="ml-3">もどる</span>
          </button>
          <button
            class="btn btn-ds mt-3 dark-blue white-text"
            v-on:click="submitApproval"
            v-waves.light
          >
            送信
          </button>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="row">
        <div class="col-12 col-md-12 col-lg-12 col-xl-9">
          <div class="row mt-4">
            <div class="col-12 mb-3">
              <b-skeleton width="20%"></b-skeleton>
            </div>
            <div class="col-12 col-md-12 col-lg-12 col-xl-6 mb-4">
              <b-skeleton class="mt-3" width="25%"></b-skeleton>
              <b-skeleton-img width="100%"></b-skeleton-img>
              <div class="p-4 lightest-grey">
                <b-skeleton class="mb-2" width="25%"></b-skeleton>
                <b-skeleton class="mb-2" width="50%"></b-skeleton>
                <b-skeleton class="mb-2" width="75%"></b-skeleton>
                <b-skeleton class="mb-2" width="50%"></b-skeleton>
                <b-skeleton class="mb-2" width="50%"></b-skeleton>
                <b-skeleton class="mb-2" width="100%"></b-skeleton>
                <b-skeleton class="mb-2" width="50%"></b-skeleton>
                <b-skeleton class="mb-2" width="75%"></b-skeleton>
                <b-skeleton class="mb-2" width="100%"></b-skeleton>
                <b-skeleton class="mb-2" width="50%"></b-skeleton>
                <b-skeleton class="mb-2" width="25%"></b-skeleton>
              </div>
            </div>
            <div class="col-12 col-md-12 col-lg-12 col-xl-6 mb-4">
              <b-skeleton class="mt-3" width="25%"></b-skeleton>
              <b-skeleton-img width="100%"></b-skeleton-img>
              <div class="p-4 lightest-grey">
                <div class="d-flex justify-content-center mb-3">
                  <b-skeleton
                    class="m-2"
                    type="avatar"
                    height="50px"
                    width="50px"
                  ></b-skeleton>
                  <b-skeleton
                    class="m-2"
                    type="avatar"
                    height="50px"
                    width="50px"
                  ></b-skeleton>
                </div>
                <b-skeleton-img height="170px" width="100%"></b-skeleton-img>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <image-modal @closeModal="closeModal()" v-if="isOpenModal">
      <img :src="modalImageUrl">
    </image-modal>
  </div>
</template>

<script>
// Import Services
import { adm_stagingService_GetSRItemsForApproval } from '../../services/admin/staging';

import {
  adm_approvalService_UpdateSRItemsSetApproved,
  adm_approvalService_UpdateSRItemsSetUnApproved,
} from '../../services/admin/approval';

import { getRequestItemVersions } from '../../common/Functions';

// Import Moment JS
import moment from 'moment';

// Import Modal
import ImageModal from '../../components/customer/Image-Modal.vue';

export default {
  metaInfo() {
    return {
      title: 'Digital Staging - Approval | View',
    };
  },
  data() {
    return {
      listingDetailsData: null,
      old_versions: [],
      old_current_versions: [],
      current_version: null,
      carousel: {
        filename: '',
      },
      pagination: {
        data: null,
        limit: 3,
        showDisabled: false,
        size: 'default',
        align: 'center',
        currentPage: this.$store.state.currentPaginationPage,
      },
      approvalsData: [],
      summary: false,
      formMessage: null,
      isOpenModal: false,
      modalImageUrl: null,
    };
  },

  mounted() {
    this.$store.state.currentPaginationPage = 1;

    this.setListingDetailsData();
    this.setCarouselData(0);
  },
  updated() {
    if (this.carousel.filename == '') {
      this.setCarouselData(0); // set delivery date of 1st image in carousel
    }
  },
  components: {
    'image-modal': ImageModal,
  },
  methods: {
    setCarouselData(i) {
      // get delivery date from data attribute of image in carousel
      var el = document.getElementById('carousel-img-' + i);
      if (el) {
        console.log('el', el, el.getAttribute('data-filename'));
        this.carousel.filename = el.getAttribute('data-filename');
      }
    },
    onSlideStart(slide) {
      this.setCarouselData(slide);
    },
    openModal(imgUrl) {
      this.modalImageUrl = imgUrl;
      this.isOpenModal = true;
    },
    setFileName(ov) {
      if (ov.result_filename) {
        return ov.result_filename;
      }
      if (ov.file_name) {
        return ov.file_name;
      }
      return ov.result_file_name;
    },
    closeModal(){
      this.isOpenModal = false;
    },
    openImage(imgUrl){
      this.$router.push({
        name: 'anonymousRoute-image',
        query: { image: imgUrl },
      });
    },
    setListingDetailsData() {
      let _this = this;

      let approvalRequestID = location.pathname.split(
        '/admin/approval/view/'
      )[1];
      let accessToken = localStorage.getItem('_accessToken');
      let page = _this.$store.state.currentPaginationPage;

      _this.listingDetailsData = null;

      adm_stagingService_GetSRItemsForApproval({
        id: approvalRequestID,
        paginated: 1,
        page_size: 1,
        page: page,
        token: accessToken,
      })
        .then((response) => {
          let data = response.data.result;
          console.log(data.data[0]);

          _this.listingDetailsData = data.data[0];
          _this.pagination.data = data;

          //get staging versions
          const { current_version, prev_versions } = getRequestItemVersions(
            data.data[0]
          );
          _this.current_version = current_version;
          _this.old_versions = prev_versions;

          console.log('test');
          console.log(_this.listingDetailsData);

          //get staging revisions
          // correctionsのresult_file_urlを取りたい
          let confirmImg = '';
          if (current_version) {
            confirmImg = current_version.result_file_url;
          }
          _this.setApprovalDataObj({
            request_item_id: data.data[0].id,
            image: data.data[0].current_revisions.revised.result_url,
            confirm_img: confirmImg,
            comment: null,
            approved: null,
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },

    setApprovalDataObj(obj) {
      let objExist = this.approvalsData.find(
        (el) => el.request_item_id === obj.request_item_id
      );

      if (!objExist) {
        this.approvalsData.push(obj);
      }
    },

    goToPrevious(from) {
      this.$store.state.currentPaginationPage = from -= 1;

      this.summary = false;

      this.setListingDetailsData();
    },

    goToNext(from) {
      this.$store.state.currentPaginationPage = from += 1;

      this.setListingDetailsData();
    },

    setSelectedApproval(listingID, status) {
      this.approvalsData.find(
        (el) => el.request_item_id === listingID
      ).approved = status;
    },

    setSummary() {
      this.summary = true;
    },

    async submitApproval() {
      let _this = this;

      _this.$store.state.modalLoaderMessage = '送信中';
      _this.$bvModal.show('modal-loader');

      let accessToken = localStorage.getItem('_accessToken');

      let approvedItem = _this.approvalsData
        .map((el) => {
          if (el.approved) {
            return el;
          }
        })
        .filter((e) => e != null);

      let disApprovedItem = _this.approvalsData
        .map((el) => {
          if (!el.approved) {
            return el;
          }
        })
        .filter((e) => e != null);

      //approve
      if (approvedItem.length > 0) {
        for (let i = 0; i < approvedItem.length; i++) {
          let el = approvedItem[i];
          await adm_approvalService_UpdateSRItemsSetApproved({
            id: el.request_item_id,
            token: accessToken,
          })
            .then((response) => {
              console.log(response);
            })
            .catch((error) => {
              console.log(error);
              setTimeout(() => {
                _this.$bvModal.hide('modal-loader');
              }, 500);
            });
        }
      }
      //disapproved
      if (disApprovedItem.length > 0) {
        await adm_approvalService_UpdateSRItemsSetUnApproved({
          items: disApprovedItem,
          token: accessToken,
        })
          .then((response) => {
            console.log('deny', response);
          })
          .catch((error) => {
            console.log(error);
            setTimeout(() => {
              _this.$bvModal.hide('modal-loader');
            }, 500);
          });
      }

      setTimeout(() => {
        _this.$bvModal.hide('modal-loader');
        _this.$router.push({
          name: 'adminRoute-approval',
        });
      }, 500);
    },
  },
};
</script>

<style scoped>
/* Init Style */
/* Custom Style */
#listing-details {
  margin-top: 40px;
}

#listing-details .image {
  height: 340px;
  background: #ececec;
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
  padding: 0;
}

@media only screen and (max-width: 1440px) {
  #listing-details .image {
    height: 300px;
  }
}

@media only screen and (max-width: 1199px) {
  #listing-details .image {
    height: 400px;
  }
}

@media only screen and (max-width: 425px) {
  #listing-details .image {
    height: 250px;
  }
}

#listing-details .image img {
  height: 100%;
  width: 100%;
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
  object-fit: cover;
}

#listing-details .image .img-carousel {
  height: 100%;
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
  background: #e9e9e9;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

#listing-details .image .img-carousel img {
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
}

#listing-details .timestamp {
  margin: 10px 0 0 20px;
}

@media only screen and (max-width: 1199px) {
  #listing-details .timestamp {
    margin: 10px 0 0 0;
    text-align: center;
  }
}

@media only screen and (max-width: 1199px) {
  #listing-details .details {
    margin-top: 60px;
  }
}

#listing-details .approval-actions button {
  height: 50px;
  width: 50px;
  font-size: 30px;
  padding: 4px;
}

#listing-details .actions,
#listing-summary-details .actions {
  margin-top: 40px;
  text-align: right;
}

#listing-details .actions .btn,
#listing-summary-details .actions .btn {
  width: 200px;
  margin-left: 40px;
}

@media only screen and (max-width: 767px) {
  #listing-details .actions,
  #listing-summary-details .actions {
    text-align: center;
  }

  #listing-details .actions .btn,
  #listing-summary-details .actions .btn {
    width: 100%;
    margin-left: 0;
  }
}

.summary-item {
  margin: 20px 2px 2px 2px;
  border-radius: 20px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.6s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.summary-item:hover {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.summary-item .image {
  height: 100%;
  background: #ececec;
  border-radius: 20px;
  padding: 0;
}

@media only screen and (max-width: 767px) {
  .summary-item .image {
    margin-bottom: 20px;
  }
}

.summary-item .image img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 20px;
}

.summary-item .details {
  padding-top: 10px;
}
</style>